import React from 'react'

export const modalId = 'search-help-modal'
const SearchHelpModal = () => (
  <div className="modal" tabIndex="-1" role="dialog" id="search-help-modal" aria-hidden="true" style={{ top: '60px', overflowY: 'hidden', height: 'calc(100% - 60px)' }}>
    <div className="modal-dialog modal-lg" role="document" style={{ width: '75%', maxWidth: '75%', height: 'calc(100% - 4.5rem)' }}>
      <div className="modal-content" style={{height: '100%'}}>
        <div className="modal-header">
          <h5>Detalii de folosire a modului de cautare "inteligenta"</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ overflowY: 'auto' }}>
          <p>Modul de cautare inteligenta poate fi folosit in 2 feluri</p>
          <ol>
            <li>cautare in titlu si text</li>
            <li>cautare dupa anumite acte sau grupuri de acte prin limbaj prescurtat</li>
          </ol>
          <h5>1. Cautare in titlu si text</h5>
          <h6>1.1. Cautare identica in titlu si text </h6>
          <p>La acest tip de cautare cuvintele introduse in casuta ce nu sunt sintagme (vezi mai jos) vor fi cautate in titlul si textul tuturor actelor din baza de date.</p>
          <p>
            Spre exemplu daca se efectueaza o cautare dupa cuvantul <b>'impozit'</b>, rezultatele vor fi toate documentele in care cuvantul 'impozit' si familia sa (impozitare, impozite, etc.) a fost gasit in titlu si/sau text.
          </p>
          <h6>1.2. Cautare separata doar titlu, doar text si/sau ambele</h6>
          <p>Acest tip de cautare mai avansata permite cautare separata in titlu, text si in ambele.<br />Pentru delimitarea lor se folosesc urmatoarele marcaje:</p>
          <ul>
            <li>Doar titlu: <b>{`{cuvinte cautate doar in titlu}`}</b></li>
            <li>Doar text: <b>[cuvinte cautate doar in text]</b></li>
            <li>In ambele: <b>cuvinte cautate si in text si in titlu</b>(fara marcaj)</li>
          </ul>
          <p>
            Spre exemplu daca se efectueaza o cautare cu textul <b>'{`{tva}`} [impozit] venit'</b>, rezultatele vor fi toate documentele in care cuvantul 'tva' este gasit doar titlu, 'impozit' doar in text si 'venit' in ambele.
          </p>
          <p>
            Rezultatele sunt ordonate descrescator dupa indicele de relevanta.<br />
            In calcularea indicelui de relevanta prioritatile sunt dupa cum urmeaza: exact in titlu si/sau text, cuvant din familie in titlu si/sau text.<br />
            In exemplu cu 'impozit' primele vor fi rezultatele care contin 'impozit' in titlu si sau text, iar apoi cele care contin in titlu si/sau text cuvinte din familie<br />
            La cautarea mai multor cuvinte vor avea indice de relevanta mai mare actele unde cuvintele au fost gasite toate cuvintele in oridinea cautata si cat mai aproape unele de altele, apoi cele in care au fost gasite partial si in ordine, iar apoi cele in care fost gasite partial si din familia fiecarui cuvant
          </p>
          <h5>2. Cautare specifica prin limbaj prescurtat</h5>
          <p>
            La acest tip de cautare motorul de cautare detecteaza anumite sintagme predefinite pentru a gasi rapid documente specifice.
          </p>
          <p>
            De exemplu:<br />
            daca se introduce in casuta <b>'mon1/2004'</b>, rezultatele vor contine toate actele din monitorul oficial nr 1 din 2004<br />
            daca se introduce in casuta <b>'tva lege/2004'</b>, rezultatele vor contine toate actele de tip lege care contin cuvantul 'tva' dupa regulile de cautare in text si titlu din orice lege din 2004<br />
          </p>
          <h6>Tipuri de sintagme</h6>
          <dl>
            <dt>Filtrare dupa monitor cu formatul <b>'mon[nrmonitor]/[anmonitor]'</b></dt>
            <dd>Rezultatele vor contine doar actele din monitorul oficial cu nr si anul specificat. Ex: <b>'mon 1/2017'</b> =&gt; doar actele din monitorul ofical nr 1 din 2017</dd>
            <dt>Filtrare dupa tip act cu formatul <b>'[tipact]'</b></dt>
            <dd>Rezultatele vor contine doar acte de tipul/-rile cautat/-e. Ex: <b>'lege hotarare'</b> =&gt; doar legi si hotarari (se pot folosi si prescurtat <b>'l h'</b>)</dd>
            <dt>Filtrare dupa tip si numar cu formatul <b>'[tipact] [nract]'</b></dt>
            <dd>Rezultatele vor contine doar acte de tipul/-rile cautat/-e. avand numerele specificate Ex: <b>'lege1 hg3'</b> =&gt; doar legi cu numarul 1 si HG-uri cu numarul 3 (se pot folosi si prescurtat <b>'l1 hg3'</b>)</dd>
            <dt>Filtrare dupa tip, numar si an cu formatul <b>'[tipact][nract]/[anpublicare]'</b></dt>
            <dd>Rezultatele vor contine doar acte de tipul/-rile cautat/-e. avand conbinatia nr/an specificata Ex: <b>'lege1/2003 oug23/2010'</b> =&gt; legea cu numarul 1 din 2003 si ordonanta de urgenta cu numarul 23 din 2010 (se pot folosi si prescurtat <b>'l1/2003 oug23/2010'</b>)</dd>
          </dl>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Am inteles</button>
        </div>
      </div>
    </div>
  </div>

)
export default SearchHelpModal
