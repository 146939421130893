import { useContext } from 'react';
import isElectron from 'is-electron';
import { useHistory, useLocation } from 'react-router-dom';
import BetterLeftNav from '../BetterLeftNav';
import { SearchContext } from '../contexts/SearchContext';
import { UserContext } from '../context';
import { modalId as searchHelpModalTargetId } from '../fragments/SearchHelpModal';
import YearTypeIndexPanel from '../YearTypeIndexSidePanel';
import YearMonIndexPanel from '../YearMonIndexSidePanel';
import TrackedDocsSidePanel from '../TrackedDocsSidePanel';
import RecentDocsSidePanel from '../RecentDocsSidePanel';
import BetterTopNav from '../BetterTopNav';
import NavSearchBox from '../fragments/NavSearchBox'

const DesktopMenu = () => {



  const { loggedInUser } = useContext(UserContext);

  const history = useHistory()
  const location = useLocation()

  const {
    refreshTrackedDocsCountAndStatus,
    unseenCount,
  } = useContext(UserContext)

  const {
    overrideSearchParams,
    clearResults,
  } = useContext(SearchContext)

  if (!loggedInUser) return null;

  return (
    <>
      <div className='desktop-menu'>
        <BetterLeftNav
          unseenCount={unseenCount}
          helpModalTargetId={searchHelpModalTargetId}
          location={location}
          subsections={
            {
              tipacte: {
                visible: false,
                items: [
                  {
                    title: "Calendar Fiscal",
                    onClick: () => {
                      if (!isElectron()) {
                        const newUrl = window.location.origin + `/viewlexlink/00013837.18`
                        const newWindow = window.open(newUrl, '_blank', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                      } else {
                        history.push('/viewlexlink/00013837.18')
                      }
                    }
                  },
                  {
                    title: "Cereri si Actiuni",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({
                        issuers: [
                          {
                            value: 'Uniunea Nationala a Notarilor Publici'
                          }, {
                            value: 'Uniunea Avocatiilor'
                          }],
                        type: [
                          {
                            value: 'CERERE'
                          }],
                      });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                  {
                    title: "Coduri",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({ type: [{ value: 'COD' }] });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                  {
                    title: "Contract\n(M.OF. PARTEA a V-a)",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({ type: [{ value: 'CONTRACT' }] });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                  {
                    title: "Decizie",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({ type: [{ value: 'DECIZIE' }] });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                  {
                    title: "Legislatie Europeana",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({ domains: [{ value: 'Legislatie Europeana' }] });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                  {
                    title: "Modele de Contract",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({ type: [{ value: 'MODEL DE CONTRACT' }] });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                  {
                    title: "Proceduri",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({ type: [{ value: 'PROCEDURA' }] });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                  {
                    title: "Spete",
                    onClick: () => {
                      clearResults();
                      overrideSearchParams({ type: [{ value: 'SPETE' }] });
                      if (!location.pathname.startsWith('/search')) {
                        history.push('/search')
                      }
                    }
                  },
                ]
              },
              actelemele: {
                visible: false,
                items: [
                  {
                    title: "Acte monitorizate",
                    badge: { type: 'warning', prop: 'unseenCount' },
                    component: <TrackedDocsSidePanel refresh={refreshTrackedDocsCountAndStatus} />
                  },
                  {
                    title: "Ultimele acte vizualizate",
                    component: <RecentDocsSidePanel />
                  },
                  // { title: "Cos gunoi", component: <h1>Cos gunoi</h1> },
                ]
              },
              calendar: {
                visible: false,
                items: [
                  {
                    title: "Index pe ani",
                    component: <YearTypeIndexPanel />
                  },
                  {
                    title: "Index monitoare",
                    component: <YearMonIndexPanel />
                  }
                ]
              },
              // documente: { visible: false, items: ["Documentele mele", "Acte monitorizate", "Ultimele vizualizate"].map(s => ({ title: s, component: <div>{s}</div> })) },
              // alerte: { visible: false, items: ["Tematici monitorizate", "Acte monitorizate"].map(s => ({ title: s, component: <div>{s}</div> })) }
            }}
        />
      </div>
      <BetterTopNav backButtonKind='light'>
        <NavSearchBox onSearch={() => {
          if (!location.pathname.startsWith('/search')) {
            history.push('/search')
          }
        }} />
      </BetterTopNav>
    </>
  )
}

export default DesktopMenu;
