import React, { useContext } from 'react'
import isElectron from 'is-electron'
import { Link, useHistory } from 'react-router-dom'
import { modalId as contactFormModalId } from './fragments/ContactModal'

import logo from './img/logo-white-h100.png'
import { UserContext } from './context'
import { SearchContext } from './contexts/SearchContext'

const BetterTopNav = ({ children, backButtonKind = 'dark' }) => {

  const { loggedInUser: { name }, logout } = useContext(UserContext);
  const { overrideSearchParams } = useContext(SearchContext)
  const history = useHistory()
  return (
    <>
      {
        isElectron()
          ? <button className={`lex-back-button btn btn-outline-${backButtonKind} lex-back-button pl-3 pr-3`}
            style={{ borderLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => history.goBack()}>
            <i className="fa fa-angle-double-left"></i>
          </button>
          : null
      }
      <div className="lex-top-nav d-flex flex-column" style={{ position: 'sticky', top: '0', zIndex: '2000' }}>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary py-0" >
          <Link
            to="/"
            className="btn btn-home btn-metallic-dark"
            onClick={() => { sessionStorage.clear(); overrideSearchParams({}) }}>
            <i className="fa fa-home"></i>
          </Link>
          <Link
            className="navbar-brand d-flex flex-row align-items-center"
            to='/'
            onClick={() => { sessionStorage.clear(); overrideSearchParams({}) }}
          >
            <div className="ml-3 ml-sm-0 ml-xs-0 d-flex flex-row align-items-center">
              <img src={logo} height="50" width="auto" style={{ height: 50, width: "auto" }} alt="logo" />
            </div>
          </Link>
          <div className="mr-auto ml-auto" style={{ flexGrow: 0.4 }}>
            {children ? !Array.isArray(children) ? children : children[0] : null}
          </div>
          <div className="d-flex flex-row align-items-center">
            <div className="navbar-text small mr-2 d-lg-block d-xl-block d-none" style={{ color: 'white', fontWeight: 100 }}>
              {name}
            </div>
            <div>
              <div className="btn btn-profile btn-metallic-dark" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user"></i></div>
              <div className="dropdown-menu" style={{ right: 0, left: 'auto' }}>
                <Link className="dropdown-item" to="/account">Contul meu</Link>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item cursor-hand" type="button" data-toggle="modal" data-target={`#${contactFormModalId}`}> Contacteaza-ne</button>
                <div className="dropdown-divider"></div>
                <div className="dropdown-item" onClick={logout}>Iesire</div>
              </div>
            </div>
          </div>
        </nav>
        {/* <div style={{ top: 0, left: 0, height: '100%', width: '100%', zIndex: -1 }}>
          <div style={{}}>
            <div style={{ left: 0, width: '100%' }}> */}
        {children && children.length === 2 ? children[1] : null}
        {/* </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default BetterTopNav
