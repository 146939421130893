import React from 'react'
import { isMobile } from './customhooks/useMobile'
import SidePanel from './fragments/SidePanel'

const DocTrackingSidePanel = ({ doc, toggleTracked }) => {
  if (!doc)
    return null
  const { tracked } = doc
  const mobile = isMobile();
  return (
    <SidePanel
      id="tracking-sidepanel"
      tooltip='Monitorizare act'
      title={<i className={`fa fa-folder-open${tracked ? '' : '-o'}`}></i>}
      top={mobile ? 80 : 400}
      width={350}
      btnclass={`btn-metallic-light`}
      topTitleComponent={<div className="panel-title w-100 fs-16 pl-2"><i className={`fa fa-folder-open${tracked ? '' : '-o'}`}></i> Monitorizare Act</div>}
    >
      <div className="d-flex justify-content-center align-items-center p-2 flex-column">
        <h5 className="pb-2">Starea curenta: <span className={`badge badge-${tracked ? "primary" : "metallic-light"}`}>{tracked ? "Monitorizat" : "Nemonitorizat"}</span></h5>
        <div className={`btn btn-outline-${!tracked ? "primary" : "metallic-light"} align-self-flex-end btn-sm`} style={{ border: 0 }} onClick={() => toggleTracked(doc)}> {tracked ? "Opreste monitorizarea" : "Monitorizeaza"} <i className="fa fa-folder-open fs-12"></i></div>
      </div>
    </SidePanel>
  )
}

export default DocTrackingSidePanel
