import React from 'react'
// import { Link } from 'react-router-dom'
import Spinner from './Spinner'

import { DocNode, docToDocNodeProps } from './Collapsibles'
import { date2DDMMMMMYYYString } from '../lib/textutils'
import { ORDERED_TYPES } from '../lib/searchutils'

// const typesComparatorF = (a, b) => { return a - b }
const typesComparator = (a, b) => {
  let aindexInTypeNamesArr = ORDERED_TYPES.indexOf(a)
  aindexInTypeNamesArr = aindexInTypeNamesArr === -1 ? (ORDERED_TYPES.length + 1) : aindexInTypeNamesArr

  let bindexInTypeNamesArr = ORDERED_TYPES.indexOf(b)
  bindexInTypeNamesArr = bindexInTypeNamesArr === -1 ? (ORDERED_TYPES.length + 1) : bindexInTypeNamesArr

  return aindexInTypeNamesArr < bindexInTypeNamesArr ? -1 : 1
}

export const LatestDocs = ({ groupedByDate, loading }) => <div className="card-body px-1 py-1 doc-index">
  <Spinner className="small" spin={loading} />
  {
    Object.keys(groupedByDate).sort().reverse().map((date, index) => {
      const groupedByMon = groupedByDate[date]
      const id = `mons-for-${date}`.replace(/\./g, '-').replace(/ /g, '-')
      return (
        <div key={id}>
          <a className="fs-16 btn btn-link text-left pl-0 font-weight-bold" data-toggle="collapse" href={`#${id}`} role="button" aria-expanded="false" aria-controls={id}>
            {date2DDMMMMMYYYString(new Date(date))}
          </a>
          <div id={id} className={`collapse ${index === 0 ? 'show' : ''}`}>
            <div className="d-flex flex-column justify-items-center align-items-start pl-0">
              {
                Object.keys(groupedByMon).map(monkey => {
                  const groupedByType = groupedByMon[monkey]
                  const id = `mons-for-${date}-${monkey}`.replace(/\./g, '-').replace(/ /g, '-')
                  return (
                    <div key={id}>
                      <a className="fs-15 btn btn-link text-left pl-0 font-weight-bold" data-toggle="collapse" href={`#${id}`} role="button" aria-expanded="false" aria-controls={id}>
                        {monkey}
                      </a>
                      <div id={id} className={`collapse ${index === 0 ? 'show' : ''}`}>
                        <div className="d-flex flex-column justify-items-center align-items-start pl-3">
                          {
                            Object.keys(groupedByType).sort(typesComparator).map(type => {
                              const docs = groupedByType[type]
                              const id = `mons-for-${date}-${monkey}-${type}`.replace(/\./g, '-').replace(/ /g, '-')
                              return (
                                <div key={id}>
                                  <div className="fs-14 font-weight-bold py-2">{type}</div>
                                  <div className="pl-3">
                                    {
                                      docs.sort((a, b) => a._source.issuers[0].issuer_nr - b._source.issuers[0].issuer_nr).map(doc => {
                                        const docnodeProps = docToDocNodeProps(doc._source, doc._id)
                                        docnodeProps.status_name = ''
                                        docnodeProps.type = ''
                                        docnodeProps.nr_text = ' '
                                        return <DocNode key={`doc-${docnodeProps._id}`} {...docnodeProps} />
                                      })
                                    }
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>)
    })
  }
</div>

export default class LatestDocsWidget extends React.PureComponent {

  render() {
    const { title, icon, items: groupedByDate, loading, onRefresh, mobile = false } = this.props
    return (
      <div className="card home-widget">
        {
          !mobile
            ? <div className="card-header d-flex justify-content-between align-items-center py-2 px-2">
              <div className="title d-flex flex-row align-items-center" style={{ gap: '0.5em' }}>
                {
                  icon
                    ? <i className={`fa fa-${icon}`} />
                    : null
                }
                {title}
              </div>
              <div className="btn btn-light btn-sm" onClick={onRefresh}>
                <i className="fa fa-refresh" />
              </div>
              {/* <div className="count">{items.length}</div> */}
            </div>
            : <div
              onClick={onRefresh}
              style={{
                position: 'absolute',
                top: 0,
                right: '0.2em',
                padding: '0 0.25em',
              }}
            >
              <i className="fa fa-refresh" />
            </div>
        }
        <LatestDocs groupedByDate={groupedByDate} loading={loading} />
      </div>
    )
  }

}
