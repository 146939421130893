import React, { useCallback, useContext, useEffect, useState } from 'react'

import { DocNode, docToDocNodeProps } from './fragments/Collapsibles'
import FullPanel from './fragments/FullPanel'
import Spinner from './fragments/Spinner'
import { date2DDMMMMMYYYString } from './lib/textutils'
import { UserContext } from './context'
import { isMobile } from './customhooks/useMobile'

const TrackedDocsSidePanel = ({ refresh }) => {

  const mobile = isMobile();

  const {
    loggedInUser,
    api,
  } = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [trackedDocs, setTrackedDocs] = useState([])

  const reload = useCallback(async () => {
    setLoading(true)
    const docs = await api.loadTrackedDocs()
    setTrackedDocs(docs)
    setLoading(false)
  }, [api])

  useEffect(reload, [reload])

  const toggleTracked = async doc => {
    await api.stopTrackingDocument(doc.revdata.chain_id)
    await reload()
  }

  const markBookmarkSeen = async doc => {
    await api.markBookmarksSeen([doc.revdata.chain_id])
    await refresh()
    setTrackedDocs(prev => prev.map(d => d === doc ? { ...doc, unseen_update: false } : d))
  }

  const markAllBookmarksSeen = async () => {
    await api.markBookmarksSeen(trackedDocs.map(d => d.revdata.chain_id))
    await refresh()
    trackedDocs.forEach(d => d.unseen_update = false)
    setTrackedDocs(prev => prev.map(d => ({ ...d, unseen_update: false })))
  }

  const reset = async () => {
    await api.resetBookmarkWhens()
    await refresh()
    await reload()
  }

  if (!trackedDocs)
    return loading ? <i className="fa fa-spinner fa-spin" /> : null

  return (
    <div className={`doc-index ${mobile ? 'card home-widget' : ''}`}>
      {loggedInUser.level === 0 && !mobile ?
        <span className="btn btn-sm btn-danger" style={{ maxWidth: '100%' }} onClick={reset}>Reseteaza data referinta acte monitorizate (vizibil si functional doar in teste!!!)</span>
        : null
      }
      <div className='d-flex justify-content-start align-items-center'>
        {
          !mobile
            ? <h5 className="text-left title my-2 p-0 mr-auto">Acte monitorizate</h5>
            : null
        }
        {
          mobile
            ? <div
              onClick={reload}
              style={{
                position: 'absolute',
                top: 0,
                right: '0.2em',
                padding: '0 0.25em',
                zIndex: 1099,
              }}
            >
              <i className="fa fa-refresh" />
            </div>
            : <>
              <div className="btn btn-light btn-sm my-2 ml-auto" onClick={reload}><i className="fa fa-refresh"></i></div>
              <div className="btn btn-light btn-sm my-2" onClick={markAllBookmarksSeen}><i className="fa primary fa-check"></i></div>
            </>
        }
      </div>
      <Spinner className="small" spin={loading} />
      <div className="d-flex flex-column align-items-start children_wrap">
        {trackedDocs.length === 0 ? <div className="font-italic text-muted small">Nu aveti acte monitorizate</div> : trackedDocs.map((doc, index) => {

          const { revdata } = doc
            , actionString = revdata.rev_type === 'Publicare' ? revdata.rev_number === 1 ? 'Publicare' : 'Republicare' : 'Modificare'
            , formattedRevDate = date2DDMMMMMYYYString(new Date(revdata.rev_date))
          return (
            <div key={`tracked-${doc._id}`} className="d-flex">
              <div className="align-self-start mr-1 text-muted fs-9" style={{ marginTop: '4px' }}>{index + 1}.</div>
              <DocNode {...docToDocNodeProps(doc, doc._id)}
                onDocOpen={() => markBookmarkSeen(doc)}
                aboutCustomBadge={!doc.unseen_update ? null : (<div role="button" className="btn p-0 track-see-btn align-self-start" onClick={() => markBookmarkSeen(doc)}> <i className="fa warning fa-exclamation-circle"></i> <i className="fa primary fa-check"></i></div>)}
                underComponent={(
                  <div className="d-flex flex-column justify-content-start align-items-start">
                    <span className="text-muted small font-italic">{actionString} in {formattedRevDate}</span>
                    <div className={`btn btn-outline-metallic-light btn-sm py-1 fs-13`} style={{ border: 0 }} onClick={() => toggleTracked(doc)}>Opreste monitorizarea <i className="fa fa-folder-open fs-12"></i></div>
                  </div>
                )}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TrackedDocsSidePanel

export const TrackedDocsSidePanelModal = (props) => <FullPanel id="trackedDocs" title={<span><i className="fa fa-list"></i> Documente monitorizate</span>}><TrackedDocsSidePanel {...props} /></FullPanel>
