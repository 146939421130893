import _ from 'lodash'

let defaultTTL = 1000 * 60 * 2
  , cache = {}
// , ttls = {}
// , lock = false
// , queue = []



const kk = {
  get: (key) => {
    if (cache.hasOwnProperty(key)) {
      // console.log(`${key} Found in cache`)
      const val = cache[key].val
      cache[key].exp = new Date().getTime() + (cache[key].ttl || defaultTTL)
      return val
    }
    return null
  }
  , set: (key, val, ttl = defaultTTL) => {
    if (val === null) {
      delete cache[key]
    } else {
      cache[key] = {
        key, ttl, val, exp: new Date().getTime() + ttl
      }
    }
  }
  ,
}
export default kk

const timeoutInterval = 1000 * 10 + 1

const check = () => {
  const expNow = new Date().getTime()
  // console.log(`Cache tick. keys: ${Object.keys(cache)} exps:${JSON.stringify(_.values(cache).map(e => ({ key: e.key, exp: e.exp })))} expnow: ${expNow}`)
  const expired = _.values(cache).filter(entry => entry.exp < expNow)

  // console.log(`Cache - Found ${expired.length} expired`)

  expired.forEach(expiredEntry => {
    delete cache[expiredEntry.key]
  })
  setTimeout(check, timeoutInterval)
}


setTimeout(check, timeoutInterval)
