import React from 'react'
import FullPageSpinner from './FullPageSpinner'
import { date2DDMMMMMYYYString } from '../lib/textutils'


const DocumentBody = ({ loading, doc }) => {

  // console.log('DOC', doc)
  if (loading && !doc)
    return <div id="document-body"><FullPageSpinner spin={loading} /></div>
  if (!doc)
    return null
  const {
    html,
    // cleanHtml,
    // reactified,
    newtype: type,
    title,
    publish_nr,
    publish_year,
    publisher_name,
    publish_date,
    revisions,
    deleted,
    // type_name: oldtype,
    status_code,
    status_name,
    issue_year,
    issuers,
    revdata,
    domains
  } = doc
    , actionString = revdata.rev_type === 'Publicare' ? revdata.rev_number === 1 ? 'Publicare' : 'Republicare' : 'Modificare'
    , formattedRevDate = date2DDMMMMMYYYString(new Date(revdata.rev_date))
    , formattedPubDate = date2DDMMMMMYYYString(new Date(publish_date))
    , issuerTexts = issuers ? issuers.map(i => `${i.issuer_name} Nr. ${i.issuer_nr}/${issue_year}`) : ""
    , singleIssuerNumber = issuers.length === 1 ? ` Nr. ${issuers[0].issuer_nr}/${issue_year}` : ""
  let statusBadgeClass = 'light'
  switch (status_code) {
    case 'A':
    case 'I':
      statusBadgeClass = 'danger'
      break;
    case 'B':
    case 'L':
    case 'O':
    case 'v':
      statusBadgeClass = 'warning'
      break;
    case 'C':
    case 'M':
      statusBadgeClass = 'info'
      break;
    case 'V':
    case 'P':
    case 'R':
    case 'r':
      statusBadgeClass = 'success'
      break;
    default:
      statusBadgeClass = 'secondary'
  }
  document.title = `Lex - ${title.replace(/<\/?[^>]+>/g, '')} - ${actionString} ${formattedRevDate}`
  return (
    <div id="fulldoc">
      {deleted && <h2 className="hide4print" style={{ color: "red" }}>ACEST DOCUMENT ESTE MARCAT CA STERS IN BAZA DE DATE</h2>}
      <div className="lex-preview-content-header py-3 text-center hide4print">
        <div className="mb-2"><strong>{publisher_name} nr. {publish_nr}/{publish_year}</strong> din <strong>{formattedPubDate}</strong></div>
        <div className="font-weight-bold">{type} {singleIssuerNumber}<br />{issuerTexts.map((it, i) => <span className="badge badge-light" key={`issuer-${it}`}>{it}</span>)}</div>
      </div>
      <div className="lex-preview-content-header text-center hide4print">
        <div className="w-100 text-center mb-2">
          <h1 style={{ fontSize: 25 }} dangerouslySetInnerHTML={{ __html: title }}></h1>
        </div>
        <small>Stare: </small><span className={`badge badge-${statusBadgeClass}`}>{status_name}</span>
        <br />
        <small>Emitenti: <strong>{issuers.map(i => i.issuer_name).join(', ')}</strong></small>
        <br />
        <small>Domenii: <strong>{domains.map(d => d.domain_name).join(', ')}</strong></small>
        <br />
      </div>
      {revdata.rev_type === 'Modificare' ?
        (
          <div className="lex-preview-content-headerxx w-100 text-center mb-2 pb-2 font-italic">
            {revdata.rev_type === 'Modificare' && revdata.rev_date === revisions[revisions.length - 1].rev_date ? 'ACT COMPUS - FORMA APLICABILA AZI!' : ''}
            {/* Acesta este actul compus creat la data de <strong>{formattedRevDate}</strong> */}
          </div>
        )
        : null}
      <div className="lex-preview-content-text data-lex-act-content">
        {/*reactified*/}
        {<div id="document-body" dangerouslySetInnerHTML={{ __html: html }} />}
      </div>
      <div><FullPageSpinner spin={loading} /></div>
    </div>

  )
}

export default DocumentBody
