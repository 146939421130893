import React from 'react'
// import { Link } from 'react-router-dom'
import SidePanel from './fragments/SidePanel'
import { date2DDMMMYYYString } from './lib/textutils'
// import reltypes from './lib/reltypes'
import { DocNode, docToDocNodeProps } from './fragments/Collapsibles'
import { isMobile } from './customhooks/useMobile'

const success_relcodes = [3, 11]
  , info_relcodes = [4, 6, 13]
  , warning_relcodes = [5, 7, 8]
  , danger_relcodes = [1, 2, 9, 10, 12]
const DocRelationsSidePanel = (props) => {
  const { doc } = props;
  const mobile = isMobile();
  return !doc || !doc.relations || doc.relations.length === 0 ? null : (
    <SidePanel
      id="relations-sidepanel"
      tooltip='Relații act'
      title={<i className="fa fa-link"></i>}
      top={mobile ? 42.5 : 350}
      width={500}
      btnclass="btn-metallic-light"
      topTitleComponent={<div className="panel-title w-100 fs-16 pl-2"><i className="fa fa-link"></i> Legaturi</div>}
    >
      <table className="table table-hover doc-index m-0">
        <tbody>
          {doc.relations
            .reverse()
            .map((rel, i) => {
              const
                { desc, other_id, other_doc: doc, rel_code } = rel
                , docnodeProps = docToDocNodeProps(doc, other_id)
                , date = new Date(doc.revdata.rev_date)
                , df = date2DDMMMYYYString(date)
              // , { publish_year: year, issuers, title, newtype: type, revdata, status_code, status_name } = doc
              // , issuer = issuers[0].issuer_name
              // , _id = other_id
              // , nr = issuers[0].issuer_nr
              // , { rev_type } = revdata
              // , docnodeProps = { year, nr, _id, title, type, rev_type, issuer, status_code, status_name }
              let badgeClass = "badge badge-pill"
              if (-1 !== success_relcodes.indexOf(rel_code)) {
                badgeClass += " badge-success"
              }
              if (-1 !== info_relcodes.indexOf(rel_code)) {
                badgeClass += " badge-info"
              }
              if (-1 !== warning_relcodes.indexOf(rel_code)) {
                badgeClass += " badge-warning"
              }
              if (-1 !== danger_relcodes.indexOf(rel_code)) {
                badgeClass += " badge-danger"
              }

              return (
                <tr key={`rel-${other_id}-${i}`}>
                  <td>
                    <div className="fs-12">
                      <span className="small text-muted">{df}</span>
                      <DocNode {...docnodeProps} aboutCustomBadge={<span className={badgeClass}>{desc}</span>} />
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </SidePanel>
  )
}

export default DocRelationsSidePanel
