import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import TopNav from './TopNav'

import { UserContext } from './context'
import { isMobile } from './customhooks/useMobile'

const MyAccount = props => {

  const { loggedInUser, updateLoggedInUser, api } = useContext(UserContext)
  const history = useHistory()
  const location = useLocation()
  const mobile = isMobile();

  const [state, setState] = useState({
    latestAdded: [], recentlyOpened: [], recentSearches: [],
    showTextMatchesInSearchResults: true, newsletter: null,
    useLatestRevisionOnLinks: false,
    name: "",
    profession: "",
    domain: "",
    city: "",
    county: "",
    phone: "",
    oldpass: "", newpass: "", newpassconfirm: ""
  })

  useEffect(() => {
    const { homeWidgets, settings } = loggedInUser.prefs
    setState({
      latestAdded: homeWidgets.latestAdded,
      recentlyOpened: homeWidgets.recentlyOpened,
      recentSearches: homeWidgets.recentSearches,
      showTextMatchesInSearchResults: settings.showTextMatchesInSearchResults,
      newsletter: settings.newsletter,
      useLatestRevisionOnLinks: settings.useLatestRevisionOnLinks,
      name: loggedInUser.name || "",
      profession: loggedInUser.profession || "",
      domain: loggedInUser.domain || "",
      city: loggedInUser.city || "",
      county: loggedInUser.county || "",
      phone: loggedInUser.phone || "",
      oldpass: "", newpass: "", newpassconfirm: ""
    })
  }, [loggedInUser])

  const updateState = obj =>
    setState(prev => ({ ...prev, ...obj }))

  const handleInputChange = event => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value;
    updateState({ [target.name]: value })
  }

  const saveProfileData = async () => {
    updateState({ savingProfileData: true })
    const result = await api.updateProfileData({
      name: state.name, profession: state.profession, domain: state.domain,
      city: state.city, county: state.county, phone: state.phone
    })
    updateLoggedInUser(result)
    updateState({ ...result, savingProfileData: false, showSaveProfileDataSuccess: true })

    //TODO: refactor this
    setTimeout(() => updateState({ showSaveProfileDataSuccess: false }), 200)
  }

  const saveUserPrefs = async () => {
    updateState({ savingUserPrefs: true })

    const newPrefs = {
      homeWidgets: {
        latestAdded: state.latestAdded,
        recentlyOpened: state.recentlyOpened,
        recentSearches: state.recentSearches,
      },
      settings: {
        showTextMatchesInSearchResults: state.showTextMatchesInSearchResults,
        newsletter: state.newsletter,
        useLatestRevisionOnLinks: state.useLatestRevisionOnLinks,
      }
    }
    const result = await api.updateUserPrefs(newPrefs)
    updateLoggedInUser({ prefs: result })

    updateState({
      latestAdded: result.homeWidgets.latestAdded,
      recentlyOpened: result.homeWidgets.recentlyOpened,
      recentSearches: result.homeWidgets.recentSearches,
      showTextMatchesInSearchResults: result.settings.showTextMatchesInSearchResults,
      newsletter: result.settings.newsletter,
      useLatestRevisionOnLinks: result.settings.useLatestRevisionOnLinks,
      savingUserPrefs: false,
      showSaveUserPrefsSuccess: true
    })

    //TODO: refactor this
    setTimeout(() => updateState({ showSaveUserPrefsSuccess: false }), 200)
  }

  const changePassword = async () => {
    if (!state.oldpass || !state.newpass || !state.newpassconfirm) {
      return updateState({ changePasswordErrors: ['Completati toate cele 3 campuri'] })
    }
    updateState({ changingPassword: true })
    const response = await api.changePassword(state.oldpass, state.newpass, state.newpassconfirm)
    if (response.ok) {
      updateState({ changingPassword: false, changePasswordErrors: [], showChangePwdSuccess: true, oldpass: "", newpass: "", newpassconfirm: "" })
    } else {
      updateState({ changingPassword: false, changePasswordErrors: response.errors })
    }
    //TODO: refactor this
    setTimeout(() => updateState({ showChangePwdSuccess: false }), 200)
  }

  const {
    latestAdded, recentlyOpened, recentSearches,

    showTextMatchesInSearchResults, newsletter, useLatestRevisionOnLinks,

    name, profession, domain, city, county, phone,

    oldpass, newpass, newpassconfirm

    , savingProfileData, savingUserPrefs, changingPassword

    , changePasswordErrors

    , showChangePwdSuccess, showSaveProfileDataSuccess, showSaveUserPrefsSuccess
  } = state

  return (
    <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto', paddingBottom: '1rem' }} className="account-screen">
      {
        !mobile
          ? <TopNav history={history} location={location} {...props.path} middleComponent={<h4 className="text-center white m-0">Contul meu</h4>} />
          : null
      }

      <div className="container py-4">
        <div className="row">
          <div className="col-12">
            <div className="black thin fs-13 text-center w-100">
              <div>
                Linia de asistenta de luni pana vineri intre orele  8:00-18:00:
              </div>
              <div>
                0256-487402 | 0788-123492 | 0788-123496
              </div>
              <div>
                Departament  marketing - vanzari:
              </div>
              <div>
                0722-264869 | 0788-123496
              </div>
              <div>
                email: <a href="mailto:info@program-legislatie.ro" className="">info@program-legislatie.ro</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={`col-${mobile ? 12 : 6}`}>

            <div className="card black mt-4">
              <div className="card-header d-flex justify-content-between py-2 px-3">
                <div className="title"><i className="fa fa-user" /> Profilul meu</div>
              </div>
              <div className="card-body">
                <div className="form">
                  <TextField title="Numele complet" name="name" value={name} onChange={handleInputChange} />
                  <TextField title="Profesie" name="profession" value={profession} onChange={handleInputChange} />
                  <TextField title="Domeniu de activitate" name="domain" value={domain} onChange={handleInputChange} />
                  <TextField title="Oras" name="city" value={city} onChange={handleInputChange} />
                  <TextField title="Judet/Sector" name="county" value={county} onChange={handleInputChange} />
                  <TextField title="Telefon" name="phone" value={phone} onChange={handleInputChange} />
                  {showSaveProfileDataSuccess ? <div className="save-success">Datele au fost salvate</div> : null}
                  <div className="btn btn-link pull-right thin" onClick={saveProfileData}>{savingProfileData ? <Spinner /> : null} Salveaza</div>
                </div>
              </div>
            </div>

            <div className="card black mt-4">
              <div className="card-header d-flex justify-content-between py-2 px-3">
                <div className="title"><i className="fa fa-eye-slash" /> Schimbare parola</div>
              </div>
              <div className="card-body">
                <div className="form">
                  <TextField title="Parola curenta" isPassword name="oldpass" value={oldpass} onChange={handleInputChange} />
                  <TextField title="Parola noua" isPassword name="newpass" value={newpass} onChange={handleInputChange} />
                  <TextField title="Confirmare parola" isPassword name="newpassconfirm" value={newpassconfirm} onChange={handleInputChange} />
                  <div className="small text-muted font-italic">* parola trebuie sa contina minim 8 caractere, o litera mare, o litera mica, o cifra si un simbol ({`~!@#$%^&*()-=+[{}]\\|;:\`'",.<>/?`.split('').map(c => <code key={`char-${c}`}>{c}</code>)})</div>
                  {changePasswordErrors ? changePasswordErrors.map((e, i) => {
                    return <div className="form-error" key={`chpwd-err-${i}`}>{e}</div>
                  }) : null}
                  {showChangePwdSuccess ? <div className="save-success">Parola a fost schimbata</div> : null}
                  <div className="btn btn-link pull-right thin" onClick={changePassword}>{changingPassword ? <Spinner /> : null} Schimba parola</div>
                </div>
              </div>
            </div>

          </div>

          <div className={`col-${mobile ? 12 : 6}`}>

            <div className="card blue mt-4">
              <div className="card-header d-flex justify-content-between py-2 px-3">
                <div className="title"><i className="fa fa-sliders" /> Setari</div>
              </div>
              <div className="card-body">
                <div className="form">

                  <div className="fs-16 thin">Widgeturi pe pagina principala</div>
                  <hr className="mt-0 mb-1" />
                  <CheckboxField title="Noutati legislative" name="latestAdded" value={latestAdded} onChange={handleInputChange} />
                  <CheckboxField title="Document recent accesate " name="recentlyOpened" value={recentlyOpened} onChange={handleInputChange} />
                  <CheckboxField title="Cautari recente" name="recentSearches" value={recentSearches} onChange={handleInputChange} />

                  <div className="fs-16 thin mt-1">Preferinte interfata</div>
                  <hr className="mt-0 mb-1" />
                  <CheckboxField title="Afiseaza cuvintele cheie din textul rezultatelor la cautari" name="showTextMatchesInSearchResults" value={showTextMatchesInSearchResults} onChange={handleInputChange} />
                  <CheckboxField title="Link-urile din text si legaturile la cea mai recenta versiune" name="useLatestRevisionOnLinks" value={useLatestRevisionOnLinks} onChange={handleInputChange} />

                  <div className="fs-16 thin mt-1">Alte setari</div>
                  <hr className="mt-0 mb-1" />
                  <CheckboxField title="Abonare newsletter" name="newsletter" value={newsletter} onChange={handleInputChange} />
                  {showSaveUserPrefsSuccess ? <div className="save-success">Setarile au fost salvate</div> : null}
                  <div className="btn btn-link pull-right thin" onClick={saveUserPrefs}>{savingUserPrefs ? <Spinner /> : null} Salveaza</div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    </div >
  )
}

const Spinner = () => <i className="fa fa-refresh fa-spin fa-fw"></i>

const TextField = props => {
  const { title, name, value, onChange, isPassword } = props
    , type = isPassword ? 'password' : 'text'
  return (
    <div className="form-group">
      <label className="sr-only">{title}</label>
      <div className="input-group mb-2 mr-sm-2 mb-sm-0">
        <input type={type} name={name} className="form-control form-control-sm" id={name} value={value} placeholder={title} onChange={onChange} />
      </div>
    </div>
  )
}

const CheckboxField = props => {
  const { title, name, value, onChange } = props
  return (
    <div className="form-group mb-2 mt-1">
      <div className="custom-control custom-checkbox">
        <input type="checkbox" className="custom-control-input" name={name} id={name} checked={value} onChange={onChange} />
        <label className="custom-control-label fs-14 thin" htmlFor={name}>{title}</label>
      </div>

    </div>
  )
}

export default MyAccount
