import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Banner from './fragments/Banner'
import * as Icons from 'react-bootstrap-icons';
import MobileInstallInstructions from './components/MobileInstallInstructions'
import FullPageSpinner from './fragments/FullPageSpinner'
import createApi from './lib/api'
import { isMobile } from './customhooks/useMobile';

const timeBetweenMobileBannerDisplays = 7 * 24 * 60 * 60 * 1000;

export const UserContext = React.createContext({
  loggedInUser: null,
  logout: null,
  login: null,
  updateLoggedInUser: null,
  unseenCount: 0,
  refreshTrackedDocsCountAndStatus: null,
  searchAggs: {},
  searchOptions: [],
  caseSearchOptions: [],
  refreshSearchAggs: null,
  api: null,
})

export const LoginContextWrapper = (props) => {

  const [loggedInUser, setLoggedInUser] = useState()
  const [unseenCount, setUnseenCount] = useState(0)
  const [searchAggs, setSearchAggs] = useState({})
  const [checked, setChecked] = useState(false)

  const [longCheck, setLongCheck] = useState(false)

  const [showMobileAddBanner, setShowMobileAddBanner] = useState(false);
  const [showMobileAddPopup, setShowMobileAddPopup] = useState(false);

  const mobile = isMobile();

  const api = useMemo(() => {
    // const apiInstance = createApi(window._env_.REACT_APP_SERVER_URL || 'http://localhost:50003',
    const apiInstance = createApi(window._env_.REACT_APP_SERVER_URL || 'https://new.program-legislatie.ro',
      ({ status, data, ok, problem, originalError }) => {
        // console.info(status, typeof data === 'object' ? Object.keys(data).length : data.length, ok, problem, originalError)
        if (ok) {
          return data
        } else {
          switch (status) {
            case 404: {
              throw new Error("404")
            }
            case 406:
            case 401:
            case 403:
              setLoggedInUser(null)
              apiInstance.clearToken()
              break
            default:
              throw new Error(data && data.error ? (data.error.message || data.error) : problem)
          }
        }
      })
    return apiInstance
  }, [])

  useEffect(() => {
    // console.log('LoginWrapper check auth', loggedInUser)
    let lcTimer
    const check = async () => {
      setLongCheck(false)
      lcTimer = setTimeout(() => {
        console.log('LONG CHECK')
        setLongCheck(true)
      }, 500)

      try {
        console.log('checking Auth')
        const user = await api.checkAuth()
        console.log('check result', user)
        setLoggedInUser(user)
        setChecked(true)
      } catch (err) {
        console.log('check failed', err)
        console.error(err)
        setChecked(true)
      }
    }

    if (!checked) {
      check()
      console.log('DONE checking')
    }
    return () => {
      console.log('Cancel LC timer')
      lcTimer && clearTimeout(lcTimer)
    }
  }, [checked, api])

  const handleCloseBanner = () => {
    localStorage.setItem('lex-mobile-last-shown', (new Date()).getTime());
    setShowMobileAddBanner(false);
  }

  useEffect(() => {
    const lastShown = localStorage.getItem('lex-mobile-last-shown');
    if (!lastShown) {
      //localStorage.setItem('lex-mobile-last-shown', (new Date()).getTime());
      setShowMobileAddBanner(true);
      return;
    }

    const diff = (new Date()).getTime() - parseInt(lastShown, 10);
    if (diff > timeBetweenMobileBannerDisplays) {
      //localStorage.setItem('lex-mobile-last-shown', (new Date()).getTime());
      setShowMobileAddBanner(true);
    }
  }, [])

  const refreshTrackedDocsCountAndStatus = useCallback(async () => {
    console.log('click');
    if (!checked || !loggedInUser) return

    const { count } = await api.getUnseenUpdateCounts()
    setUnseenCount(parseInt(count, 10))

  }, [checked, loggedInUser, api])

  useEffect(() => {
    refreshTrackedDocsCountAndStatus()
  }, [refreshTrackedDocsCountAndStatus])

  const refreshSearchAggs = useCallback(async (force = false) => {
    if (!checked || !loggedInUser) return
    const cachedAggsStr = localStorage.getItem('SearchAggs')
    const { when, aggs } = cachedAggsStr ? JSON.parse(cachedAggsStr) : {}
    if (!force && when && new Date() - new Date(when) < 24 * 60 * 60 * 1000) {
      setSearchAggs(aggs)
    } else {
      // need to refresh
      const dbAggs = await api.loadSearchAggregations()
      localStorage.setItem('SearchAggs', JSON.stringify({ when: new Date().getTime(), aggs: dbAggs }))
      setSearchAggs(dbAggs)
    }
  }, [checked, loggedInUser, api])

  useEffect(() => {
    refreshSearchAggs()
  }, [refreshSearchAggs])

  const searchOptions = useMemo(() => [
    {
      name: 'id',
      label: 'Numărul actului',
      type: 'text',
    },
    {
      name: 'year',
      label: 'Anul actului',
      type: 'text',
    },
    {
      name: 'id_mof',
      label: 'Numărul Monitorului Oficial',
      type: 'text'
    },
    {
      name: 'year_mof',
      label: 'Anul Monitorului Oficial',
      type: 'text'
    },
    {
      name: 'text',
      title: 'Cuvinte',
      label: 'Alege tip',
      secondLabel: 'Adaugă cuvântul',
      type: 'list',
      canTriggerSearchOnEnter: true,
      source: [{ value: 'Text', selected: true }, { value: 'Titlu' }, { value: 'Titlu și text' }],
    },
    {
      name: 'issuers',
      title: 'Emitenți',
      label: 'Adaugă emitent',
      type: 'list',
      source: searchAggs && searchAggs.issuers ? searchAggs.issuers.map(i => ({ value: i })) : []
    },
    {
      name: 'domains',
      title: 'Tematici',
      label: 'Adaugă tematică',
      type: 'list',
      source: searchAggs && searchAggs.domains ? searchAggs.domains.map(i => ({ value: i })) : []
    },
    {
      name: 'type',
      title: 'Tipul Actului',
      label: 'Adaugă tip',
      type: 'list',
      source: searchAggs && searchAggs.types ? searchAggs.types.map(i => ({ value: i })) : []
    },
  ], [searchAggs])

  const caseSearchOptions = useMemo(() => [
    {
      name: 'institutions',
      title: 'Instituții',
      label: 'Alege instituție',
      type: 'list',
      source: searchAggs && searchAggs.institutions ? searchAggs.institutions.map(i => ({ value: i })) : []
    },
    {
      name: 'startTime',
      label: 'Data start',
      type: 'date',
    },
    {
      name: 'stopTime',
      label: 'Data stop',
      type: 'date',
    },
    {
      name: 'lastUpdateStartDate',
      label: 'Data start ultima modificare',
      type: 'date',
    },
    {
      name: 'lastUpdateStopDate',
      label: 'Data stop ultima modificare',
      type: 'date',
    },
    {
      name: 'number',
      label: 'Numărul dosarului',
      type: 'text',
    },
    {
      name: 'object',
      label: 'Obiectul dosarului',
      type: 'text',
    },
    {
      name: 'part',
      label: 'Nume parte',
      type: 'text'
    },
  ], [searchAggs])


  const updateLoggedInUser = useCallback(patch => setLoggedInUser(prev => ({ ...prev, ...patch })), [])
  const login = useCallback(async (email, pass) => {
    const user = await api.authenticate(email, pass)
    setLoggedInUser(user)
  }, [api])

  const logout = useCallback(async () => {
    await api.logout()
    setLoggedInUser(null)
  }, [api])

  if (!checked && longCheck) {
    return <div><FullPageSpinner spin={true} message={''} /></div>
  }

  if (!checked) return null

  return (
    <UserContext.Provider value={{
      loggedInUser,
      updateLoggedInUser,
      login,
      logout,
      unseenCount, refreshTrackedDocsCountAndStatus,
      searchAggs, refreshSearchAggs,
      searchOptions,
      caseSearchOptions,
      api,
    }}>
      {
        loggedInUser && showMobileAddBanner && !mobile
          ? <Banner
            text={<span style={{ cursor: 'pointer' }} onClick={() => setShowMobileAddPopup(true)}>Încearcă nouă aplicație mobilă. Apasă aici pentru detalii.</span>}
            type='info'
            onDismiss={() => handleCloseBanner()}
            className='lex-mobile-add-banner'
          />
          : null
      }
      {
        loggedInUser && showMobileAddPopup && !mobile
          ? <MobileInstallInstructions handleClose={() => setShowMobileAddPopup(false)} />
          : null
      }
      {props.children}
    </UserContext.Provider >
  )
}

export const SettingsContext = React.createContext({
  showTextMatchesInResults: true,
  showReds: true,
  updateSettings: () => {
    // TODO: do this
    alert('WIP')
  }
})
