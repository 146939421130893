let ws
export const connect = (messageHandler) => {
    ws = new WebSocket('ws://localhost:9877', [])
    ws.onopen = () => {
        console.log('Connected to server for notifications')
    }
    ws.onmessage = e => messageHandler(JSON.parse(e.data))
    ws.onerror = e => console.log('Error connecting to server for notifications', e.message)
    ws.onclose = e => {
        console.log('Connection closed', e.code, e.reason)
    }
}

export const disconnect = () => {
    console.log('Disconnecting from WSS', ws ? ws.readyState : 'ws not connected')
    if (ws && ws.readyState !== WebSocket.CLOSED) {
        ws.close(1000, 'something changed')
    }
}
