import React from 'react'

import logo from '../img/logo-blue-h100.png'

const FullPageSpinner = ({ spin, style, message = 'Se incarca...' }) => spin ? (
  <div className={'d-flex justify-content-center align-items-center'} style={{
    position: 'fixed',
    height: '100%', width: '100%',
    left: 0, top: 0, zIndex: 9999,
    backgroundColor: 'rgba(255,255,255,0.5)',
    ...style
  }}>
    <div className={'fs-20'} style={{ color: '#0055cc', padding: 10, borderWidth: 1, borderColor: '#0055cc', borderStyle: 'solid', backgroundColor: 'rgba(255,255,255,0.5)' }}>
      <img src={logo} height="50" width="auto" style={{ height: 50, width: "auto" }} alt="logo"></img>
      <i className="fa fa-refresh fa-spin fa-fw"></i>{message}
    </div>
  </div>
) : null

export default FullPageSpinner
