import React, { useCallback, useContext, useEffect, useState } from 'react'

import FullPanel from './fragments/FullPanel'
import { RecentDocs } from './fragments/RecentDocsWidget'
import { UserContext } from './context'

const RecentDocsSidePanel = () => {

  const { api } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [recentDocs, setRecentDocs] = useState(false)

  const reload = useCallback(() => {
    setLoading(true)

    api.recent(50).then(docs => {
      setRecentDocs(docs)
      setLoading(false)
    })

  }, [api])

  useEffect(reload, [reload])

  if (!recentDocs)
    return loading ? <i className="fa fa-spinner fa-spin" /> : null

  return (
    <div className="doc-index">
      <div className="d-flex justify-content-start align-items-center">
        <h5 className="text-left title my-2 p-0 mr-auto">Documente recent vizualizate</h5>
        <div className="btn btn-light btn-sm my-2" onClick={reload}><i className="fa fa-refresh"></i></div>
      </div>
      <RecentDocs recentDocsSortedByDate={recentDocs} loading={loading} />
    </div>
  )
}

export default RecentDocsSidePanel

export const RecentDocsSidePanelModal = (props) => <FullPanel id="trackedDocs" title={<span><i className="fa fa-list"></i> Recent accesate</span>}><RecentDocsSidePanel {...props} /></FullPanel>
