import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../context'
export const collapseAll = (arr) => {
  Object.keys(arr).forEach(key => arr[key].collapsed = true)
}


const Collapse = ({ collapsed, onClick, children, additionalClasses, ...otherProps }) => <div onClick={onClick} {...otherProps} className={`collapser ${additionalClasses} py-0`}><i className={`toggler small fa fa-${collapsed ? 'plus-square-o' : 'minus-square-o'}`}></i> {children}</div>

export const docToDocNodeProps = (doc, _id) => {
  const { issue_year: year, issuers, title, newtype: type, revdata, status_code, status_name } = doc
    , issuer = issuers[0].issuer_name
    , nr = issuers[0].issuer_nr
    , { rev_type } = revdata
    , docnodeProps = { year, nr, _id, title, type, rev_type, issuer, status_code, status_name }
  return docnodeProps
}

export const DocNode = props => {

  const { loggedInUser } = useContext(UserContext)

  const {
    year, issue_year, nr, nr_text, _id, title, type, rev_type,
    issuer, status_code, status_name, aboutCustomBadge,
    revdataComponent, underComponent, overComponent
    , onDocOpen
  } = props
  const typeNameShort = type
  const level0 = loggedInUser.level === 0
  const typeSpan = typeNameShort ? <span className="type-label mr-1">{typeNameShort}{rev_type === "Republicare" ? ' (R)' : ''}</span> : null
  return (
    <div className="d-flex flex-column doc position-relative">
      {overComponent}
      <div className="about d-flex align-items-center justify-content-start">
        {aboutCustomBadge ? aboutCustomBadge : null}
        <a href={`/view/${_id}`} className="doclink" target="_self" rel="noreferrer" onClick={onDocOpen}>
          {typeSpan}
          <span className="font-italic pr-1">{`${issuer.replace(',', ', ')}`}</span>
          <span className="issuer-label pr-1">{`${nr_text || 'nr. '}`}<span className="btn-link">{`${nr}/${issue_year || year}`}</span></span>
          <strong className={`status status-${status_code}  pr-1`}>{status_name}</strong>
        </a>
        {revdataComponent}
      </div>
      <a href={`/view/${_id}`} className="doclink" target="_self" rel="noreferrer" onClick={onDocOpen}>
        <div className="doctitle">{title}</div>
      </a>
      {underComponent}
      {level0 ? <a href={`${(window._env_ && window._env_.REACT_APP_ADMIN_URL) || window._env_.REACT_APP_ADMIN_URL}/editact/${_id}`} target="_self" rel="noreferrer" style={{ width: 'max-content' }}>Edit</a> : null}
    </div>
  )
}

const CollapsibleLeafSequence = ({ collapsed, nodeClass, type, title, doc_count, docs, onClick }) => {
  return (
    <div className={`leaf ${nodeClass || ''}`}>
      <Collapse collapsed={collapsed} style={{ width: 'auto' }} onClick={onClick} additionalClasses={"mx-0"}>
        <strong>{title}</strong>
        {/* <span className="small doc-count">{doc_count}</span> */}
      </Collapse>
      {collapsed ? null : (
        <div className="docs">
          {docs.length === 0 ? <i className="fa fa-spinner fa-spin" /> : docs.map(d => <DocNode key={d._id} {...d} />)}
        </div>
      )}

    </div>
  )
}

export const CollapsibleNodeSequence = ({ collapsed, nodeClass, depth, type, title, doc_count, children, onClick, groupChildren, toggleNodeFunction, toggleLeafFunction }) => {
  return (
    <div className={`node depth-${depth} ${nodeClass || ''}`} key={`${title}`}>
      <Collapse collapsed={collapsed} style={{ width: groupChildren ? 150 : 'auto' }} onClick={onClick} additionalClasses={""}>
        <strong>{title}</strong>
        {/* <span className="small doc-count">{doc_count}</span> */}
      </Collapse>
      {collapsed ? null : (
        <div className={`children_wrap ${groupChildren ? 'grouped d-flex flex-row flex-wrap justify-content-start' : 'd-flex flex-column align-items-start'}`}>
          {children.map((child, index) => {

            return child.type === "node" ? (
              <CollapsibleNodeSequence key={`${title}-${index}`} {...child} toggleNodeFunction={toggleNodeFunction} toggleLeafFunction={toggleLeafFunction} onClick={() => toggleNodeFunction(children, index)} />
            ) : (<CollapsibleLeafSequence key={`${title}-${index}`} {...child} onClick={() => toggleLeafFunction(children, index, child.loadArgs)} />)

          })
          }
        </div>
      )
      }
    </div>
  )
}
