
import { useContext } from 'react';
import { Route } from 'react-router';
import { UserContext } from '../context';
import { Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }: { component: any } & Record<string, any>) => {
  const { loggedInUser } = useContext(UserContext)
  return (
    <Route {...rest} render={props => {
      return (
        loggedInUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: `/login`,
            search: props.location.pathname === '/' ? undefined : 'r=' + encodeURIComponent(props.location.pathname)
          }} />
        )
      )
    }} />
  )
}

export default PrivateRoute;
