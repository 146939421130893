import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as Icons from 'react-bootstrap-icons'
import '../styles/advanced-search.scss'
import { SearchContext, ComplexSearchItem, SearchParms } from '../contexts/SearchContext'
import { UserContext } from '../context'
import { isMobile } from '../customhooks/useMobile'
import { useHistory } from 'react-router'

type SelectItem = {
  value: string,
  label?: string,
  selected?: boolean,
}

enum SearchModes {
  DOCUMENTS = 'documente',
  CASES = 'dosare',
}

const Label = ({
  text,
  canRemove = false,
  onRemoveItem
}: {
  text: string,
  canRemove?: boolean,
  onRemoveItem: () => void,
}) => {
  return (
    <div className='form-control batman-label d-flex flex-row justify-items-between align-items-center w-100'>
      <div className='batman-label-title'>
        {text}
      </div>
      <Icons.XCircle onClick={onRemoveItem} className='ml-auto pointer' style={{ color: 'red' }} />
    </div>
  )
}

const DropdownListPanel = ({
  name,
  secondaryName,
  dropdownItems,
  canEnterText = false,
  items = [],
  onAddItem,
  onRemoveItem
}: {
  name: string,
  secondaryName?: string,
  dropdownItems: SelectItem[],
  canEnterText?: boolean,
  items?: ComplexSearchItem[],
  onAddItem: (item: ComplexSearchItem) => void,
  onRemoveItem: (item: ComplexSearchItem) => void,
}) => {
  const [text, setText] = useState('');
  const [selectedValue, setSelectedValue] = useState(dropdownItems.find(i => i.selected)?.value || 'None');

  const handleKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      onAddItem({ type: selectedValue, value: text })
    }
  }

  return (
    <>
      <div className='d-flex flex-row align-items-center justify-items-between w-100 px-1' style={{ gap: '0.5em' }}>
        <div className="form-floating w-100">
          {/* <label htmlFor={`form-field-${name}`}>{name}</label> */}
          <select
            id={`form-field-${name}`}
            className="form-select form-control px-2 w-100"
            // value={undefined}
            placeholder="Please select something"
            onChange={e => canEnterText
              ? setSelectedValue(e.target.value)
              : onAddItem({ value: e.target.value })
            }
          >
            {
              !canEnterText
                ? <option
                  value='None'>
                </option>
                : null
            }

            {
              dropdownItems
                .filter(i => canEnterText ? true : !items.find(sI => sI.value === i.value))
                .map(o =>
                  <option
                    key={`form-field-${name}-${o.value || o}`}
                    value={o.value || o.label}>
                    {o.label || o.value}
                  </option>
                )
            }
          </select>
        </div>
        {
          canEnterText
            ? <>
              <div className="form-floating w-100">
                {/* <label htmlFor="floatingInput">{secondaryName}</label> */}
                <form onSubmit={e => e.preventDefault()} >
                  <input
                    type='text'
                    className={`form-control`}
                    id={name}
                    value={text}
                    onChange={e => setText(e.target.value)}
                    onKeyUp={e => handleKeyUp(e)}
                  />
                </form>
              </div>
              <Icons.PlusCircle
                title={!text ? 'Introdu textul pentru cautare' : undefined}
                className={`ml-auto pointer ${!text ? 'disabled' : ''}`} style={{ color: '#008cff', fontSize: '2.6em', marginRight: '0.3em' }}
                onClick={() => { if (text) onAddItem({ type: selectedValue, value: text }) }}
              />
            </>
            : null
        }

      </div>
      <div className='batman-dropdown-list-options'>
        {
          items.map((o, idx) =>
            <Label
              key={idx}
              text={
                o.type
                  ? `${o.type}: ${o.value}`
                  : o.value
              }
              canRemove
              onRemoveItem={() => onRemoveItem(o)}
            />
          )
        }
      </div>
    </>
  )
}

const AdvancedSearchBoxNew = ({
  onHide,
  onSearch,
}: {
  onHide: () => void,
  onSearch: () => void,
}) => {
  const { searchParams, overrideSearchParams, isCaseSearch, setIsCaseSearch, clearResults } = useContext(SearchContext)
  const { searchAggs, searchOptions, caseSearchOptions, } = useContext(UserContext);
  const [searchMode, setSearchMode] = useState(isCaseSearch ? SearchModes.CASES : SearchModes.DOCUMENTS);

  const [localDocumentsSearchParams, setLocalDocumentsSearchParams] = useState<SearchParms>({})
  const [localCasesSearchParams, setLocalCasesSearchParams] = useState<SearchParms>({});

  const mobile = isMobile();
  const history = useHistory();

  const localSearchParams = useMemo(() => {
    if (searchMode === SearchModes.DOCUMENTS) {
      return localDocumentsSearchParams
    }
    if (searchMode === SearchModes.CASES) {
      return localCasesSearchParams
    }
    return {}
  }, [localCasesSearchParams, localDocumentsSearchParams, searchMode])

  const updateLocalSearchParams = (name: string, items: string | ComplexSearchItem[]) => {
    if (searchMode === SearchModes.DOCUMENTS) {
      setLocalDocumentsSearchParams(prev => ({ ...prev, [name]: items }))
    }
    if (searchMode === SearchModes.CASES) {
      setLocalCasesSearchParams(prev => ({ ...prev, [name]: items }))
    }
  }

  const addItem = (name: string, item: ComplexSearchItem, canTriggerSearchOnEnter?: boolean) => {
    if (!item.value) return;
    const existingItems = (localSearchParams[name] || []) as ComplexSearchItem[]
    const isItemInList = !!existingItems.find((i: ComplexSearchItem) => i.type === item.type && i.value === item.value)

    if (canTriggerSearchOnEnter && isItemInList) {
      triggerSearch();
    } else {
      const updatedItems = !isItemInList
        ? [...existingItems, item]
        : existingItems
      updateLocalSearchParams(name, updatedItems);
    }
  }

  const removeItem = (name: string, item: ComplexSearchItem) => {
    const existingItems = (localSearchParams[name] || []) as ComplexSearchItem[]
    const updatedItems = existingItems.filter((i: ComplexSearchItem) => item.type
      ? (i.type !== item.type || (i.type === item.type && i.value !== item.value))
      : (i.value !== item.value))
    updateLocalSearchParams(name, updatedItems);
  }

  const toggleSearch = (mode: SearchModes) => {
    setSearchMode(mode);
  }

  const finalOptions = useMemo(() => {
    switch (searchMode) {
      case SearchModes.DOCUMENTS:
        return searchOptions;
      case SearchModes.CASES:
        return caseSearchOptions;
    }
  }, [caseSearchOptions, searchMode, searchOptions])

  const triggerSearch = () => {
    overrideSearchParams({ ...localCasesSearchParams, ...localDocumentsSearchParams, isCaseSearch: searchMode === SearchModes.CASES });
    if (isCaseSearch !== (searchMode === SearchModes.CASES)) {
      clearResults();
    }
    setIsCaseSearch(searchMode === SearchModes.CASES);
    onSearch();
  }

  const triggerQuickSearch = () => {
    if (isCaseSearch !== (searchMode === SearchModes.CASES)) {
      clearResults();
    }
    setIsCaseSearch(searchMode === SearchModes.CASES);
    onSearch();
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      triggerSearch();
      e.preventDefault();
    }
  }


  useEffect(() => {
    const caseParams: SearchParms = {}
    const docsParams: SearchParms = {}

    searchOptions.forEach((option: any) => {
      docsParams[option.name] = searchParams[option.name]
    })

    caseSearchOptions.forEach((option: any) => {
      caseParams[option.name] = searchParams[option.name]
    })

    setLocalDocumentsSearchParams(docsParams);
    setLocalCasesSearchParams(caseParams);
  }, [caseSearchOptions, searchOptions, searchParams])

  return (
    <div className='batman-advanced-search' onScroll={e => e.preventDefault()} style={{ height: mobile ? 'calc(100% - 84px)' : 'calc(100% - 60px)' }}>
      <div className='batman-advanced-search-type-toggle'>
        <div
          className={`batman-advanced-search-type-toggle-button ${searchMode === SearchModes.DOCUMENTS ? 'active' : ''}`}
          onClick={() => toggleSearch(SearchModes.DOCUMENTS)}
        >
          Legislatie
        </div>
        <div
          className={`batman-advanced-search-type-toggle-button ${searchMode === SearchModes.CASES ? 'active' : ''}`}
          onClick={() => toggleSearch(SearchModes.CASES)}
        >
          Dosare instanta
        </div>
        {
          mobile
            ? <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle batman-advanced-search-more-options batman-advanced-search-type-toggle-button" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Cautare rapida
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <div
                  className='dropdown-item'
                  onClick={() => history.push('/viewlexlink/00013837.18')}
                >
                  Calendar Fiscal
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({
                      issuers: [
                        {
                          value: 'Uniunea Nationala a Notarilor Publici'
                        }, {
                          value: 'Uniunea Avocatiilor'
                        }],
                      type: [
                        {
                          value: 'CERERE'
                        }],
                    });
                    triggerQuickSearch();
                  }}
                >
                  Cereri si Actiuni
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({ type: [{ value: 'COD' }] });
                    triggerQuickSearch();
                  }}
                >
                  Coduri
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({ type: [{ value: 'CONTRACT' }] });
                    triggerQuickSearch();
                  }}
                >
                  Contracte (M.OF. PARTEA a V-a)
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({ type: [{ value: 'DECIZIE' }] });
                    triggerQuickSearch();
                  }}
                >
                  Decizii
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({ domains: [{ value: 'Legislatie Europeana' }] });
                    triggerQuickSearch();
                  }}
                >
                  Legislatie Europeana
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({ type: [{ value: 'MODEL DE CONTRACT' }] });
                    triggerQuickSearch();
                  }}
                >
                  Modele de Contract
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({ type: [{ value: 'PROCEDURA' }] });
                    triggerQuickSearch();
                  }}
                >
                  Proceduri
                </div>
                <div
                  className='dropdown-item'
                  onClick={() => {
                    overrideSearchParams({ type: [{ value: 'SPETE' }] });
                    triggerQuickSearch();
                  }}
                >
                  Spete
                </div>
              </div>
            </div>
            : null
        }
      </div>
      <div className='batman-advanced-search-content batman-wrapper'>
        <div className='batman-advanced-search-content-panel large'>
          <div className='form py-2 px-2 bg-light w-100 h-100'>
            <div className='d-flex flex-column batman-gap-half form-control h-100'>
              <div className='batman-advanced-search-content batman-gap-one py-1 ids'>
                {
                  finalOptions
                    .filter((o: any) => o.type !== 'list')
                    .map((item: any, idx) =>
                      <div key={idx} className="form-floating batman-advanced-search-content-half">
                        <label htmlFor="floatingInput">{item.label}</label>
                        <input
                          type={item.type}
                          className={`form-control`}
                          id={item.name}
                          value={(localSearchParams[item.name] as string) || ''}
                          onChange={(e) => updateLocalSearchParams(item.name, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
        {
          finalOptions
            .filter((o: any) => o.type === 'list')
            .map((item: any, idx) =>
              <div className='batman-advanced-search-content-panel' key={idx}>
                <div className='form py-2 px-2 bg-light w-100 h-100'>
                  <div className='d-flex flex-column batman-gap-half form-control h-100'>
                    <div className='batman-advanced-search-content-panel-title'>
                      {item.title}
                    </div>
                    <DropdownListPanel
                      name={item.label}
                      secondaryName={item.secondLabel}
                      canEnterText={!!item.secondLabel}
                      dropdownItems={item.source || []}
                      onAddItem={(i) => addItem(item.name, i, item.canTriggerSearchOnEnter)}
                      onRemoveItem={(i) => removeItem(item.name, i)}
                      items={localSearchParams[item.name] as ComplexSearchItem[]}
                    />
                  </div>
                </div>
              </div>
            )
        }
      </div>
      <div className='batman-advanced-search-actions w-100'>
        {
          !mobile
            ? <div className='fake-btn' />
            : null
        }
        <button className={`btn btn-primary ${mobile ? 'mr' : 'ml'}-auto`} onClick={() => triggerSearch()}>Caută</button>
        <button className='btn btn-danger' onClick={() => overrideSearchParams({})}>Șterge filtre</button>
        <button
          className='btn btn-secondary ml-auto'
          onClick={() => {
            if (mobile) {
              history.push('/');
            } else {
              onHide();
            }
          }}
        >
          Renunță</button>
      </div>
    </div >
  )
}

export default AdvancedSearchBoxNew;
