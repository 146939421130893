import '../styles/mobile-add.scss';
import * as Icons from 'react-bootstrap-icons';
import { useState } from 'react';

const CollapsibleIcon = ({ collapsed }: { collapsed: boolean }) => {
  return (
    collapsed
      ? <Icons.ChevronRight className='lex-collapsible-panel-icon' />
      : <Icons.ChevronDown className='lex-collapsible-panel-icon' />
  )
}

const CollapsiblePanel = ({ header, content, footer, isCollapsed = false }: { header: any, content: any, footer?: any, isCollapsed?: boolean }) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);

  return (
    <div className='lex-collapsible-panel'>
      <div className='lex-collapsible-panel-header' onClick={() => setCollapsed(prev => !prev)}>
        <h1>{header} <CollapsibleIcon collapsed={collapsed} /></h1>
      </div>
      {
        !collapsed
          ? <div className='lex-collapsible-panel-content'>
            {content}
          </div>
          : null
      }
      {
        !collapsed
          ? footer || null
          : null
      }
    </div>
  )
}

const MobileInstallInstructions = ({ handleClose }: { handleClose: () => void }) => {

  return (
    <div className='lex-modal'>
      <div className='lex-modal-content'>
        <div className='lex-modal-content-title'>
          LEX Mobile
          <Icons.X onClick={() => handleClose()} className='lex-banner-close-icon ml-auto' />
        </div>
        <div className='lex-modal-content-text lex-mobile-add'>
          <p>
            Am lansat nouă aplicație mobilă care funcționează în sistem PWA și astfel nu necesită instalare de pe Apple Store sau Google Play.
          </p>
          <CollapsiblePanel
            header='Instrucțiuni instalare iOS'
            isCollapsed
            content={
              <>
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/ios-mobile-app/ios-1.png' alt='' />
                  <p>Deschideți <b>Safari</b></p>
                  <div className='lex-column-id'>1</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/ios-mobile-app/ios-2.png' alt='' />
                  <p>Navigați la adresa</p>
                  <p><i>https://program-legislatie.ro</i></p>
                  <p>și apăsați pe icoana <b>Distribuie</b></p>
                  <div className='lex-column-id'>2</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/ios-mobile-app/ios-3.png' alt='' />
                  <p>Selectați <b>Adăugați la Ecranul principal</b> (Add to Home Screen)</p>
                  <div className='lex-column-id'>3</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/ios-mobile-app/ios-4.png' alt='' />
                  <p>Apăsați butonul <b>Adăugați</b> (Add)</p>
                  <div className='lex-column-id'>4</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/ios-mobile-app/ios-5.png' alt='' />
                  <p>Aplicația poate acum fi deschisă de pe ecranul principal</p>
                  <div className='lex-column-id'>5</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/ios-mobile-app/ios-6.png' alt='' />
                  <div className='lex-column-id'>6</div>
                </div>
              </>
            }
            footer={<p className='mt-3'><b>Notă:</b> Pe iOS pentru navigarea înainte-înapoi, glisați din marginea stânga a telefonului înspre centru pentru înainte, respectiv glisați din marginea dreapta a telefonului înspre centru pentru înapoi.</p>}
          />
          <CollapsiblePanel
            header='Instrucțiuni instalare Android'
            isCollapsed
            content={
              <>
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/android-mobile-app/android-1.png' alt='' />
                  <p>Deschideți <b>Chrome</b></p>
                  <div className='lex-column-id id2'>1</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/android-mobile-app/android-2.png' alt='' />
                  <p>Navigați la adresa</p><p>https://program-legislatie.ro</p>
                  <p>și apăsați pe icoana <b>Mai multe</b></p>
                  <div className='lex-column-id'>2</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/android-mobile-app/android-3.png' alt='' />
                  <p>Selectați <b>Instalați aplicație</b> </p>
                  <p>(Install app)</p>
                  <div className='lex-column-id'>3</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/android-mobile-app/android-4.png' alt='' />
                  <p>Apăsați butonul <b>Instalați</b> (Install)</p>
                  <div className='lex-column-id'>4</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/android-mobile-app/android-5.png' alt='' />
                  <p>Aplicația poate acum fi deschisă de pe ecranul principal</p>
                  <div className='lex-column-id id2'>5</div>
                </div>
                <Icons.ChevronRight className='lex-collapsible-panel-icon' />
                <div className='lex-column'>
                  <img className='lex-mobile-app-instructions-image' src='/img/android-mobile-app/android-6.png' alt='' />
                  <div className='lex-column-id'>6</div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default MobileInstallInstructions;
