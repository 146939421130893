import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import { UserContext } from '../context';

const MenuItem = ({ text, icon, iconActive = undefined, active = false, floatingCount = 0, onClick }: { text: string, icon: JSX.Element, iconActive?: JSX.Element, active?: boolean, floatingCount?: number, onClick?: () => void }) => {
  const calculatedCount = useMemo(() => floatingCount > 9 ? '9+' : floatingCount, [floatingCount])
  return (
    <div className={`mobile-menu-item ${active ? 'active' : ''}`} onClick={onClick}>
      <div className='mobile-menu-item-icon'>
        {active ? iconActive || icon : icon}
      </div>
      <div className='mobile-menu-item-text'>
        {text}
      </div>
      {
        floatingCount
          ? <div className='mobile-menu-item-floating-count'>
            {calculatedCount}
          </div>
          : null
      }
    </div>
  )
}

const MobileMenu = () => {
  const { unseenCount } = useContext(UserContext);
  const [activeItem, setActiveItem] = useState('Acasa');
  const history = useHistory();

  const menuItems = useMemo(() => [
    {
      text: "Acasa",
      icon: <Icons.HouseDoor />,
      iconActive: <Icons.HouseDoorFill />,
      route: '/',
    },
    {
      text: "Monitorizate",
      icon: <Icons.FileEarmark />,
      floatingCount: unseenCount,
      route: '/monitored',
    },
    {
      text: "Index",
      icon: <Icons.Calendar3 />,
      route: '/index',
    },
    {
      text: "Cautare",
      icon: <Icons.Search />,
      route: '/advanced-search',
    },
    {
      text: "Cont",
      icon: <Icons.Person size="1.3em" />,
      route: '/account',
    },
  ], [unseenCount])

  useEffect(() => {
    const activemenuItem = menuItems.find(mI => history.location.pathname === mI.route);
    if (activemenuItem) {
      setActiveItem(activemenuItem.text);
    }
  }, [history.location.pathname, menuItems])

  return (
    <div className='mobile-menu'>
      {
        menuItems.map((item, idx) =>
          <MenuItem
            key={idx}
            {...item}
            onClick={() => {
              setActiveItem(item.text);
              if (item.route) {
                history.push(item.route);
              } else {
                //item.onClick?.();
              }
            }}
            active={item.text === activeItem}
          />
        )
      }

      {/* <Icons.ChatSquareText /> */}
      {/* <Icons.Folder /> */}
      {/* <Icons.Send /> */}
    </div>
  )
}

export default MobileMenu;
