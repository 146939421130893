import { useEffect, useState } from "react"

export const useClikedOutside = (ref, targetClass) => {
  const [clikedOutside, setClikedOutside] = useState(false)

  const hasSomeParentTargetClass = (element, classname) => {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0)
      return true;

    return element.parentNode && hasSomeParentTargetClass(element.parentNode, classname);
  }

  function handleClickOutside(event, flag) {
    if (ref.current && !ref.current.contains(event.target) && flag && !hasSomeParentTargetClass(event.target, targetClass))
      setClikedOutside(true)
    else
      setClikedOutside(false)

  }
  useEffect(() => {
    document.addEventListener("mousedown", (event) => handleClickOutside(event, true));

    return () => {
      document.removeEventListener("mousedown", (event) => handleClickOutside(event, false));
    };
  // don't fix this useEffect missing dep. it's on purpose
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, clikedOutside]);

  return clikedOutside
}
