import { string } from "prop-types";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Record } from "react-bootstrap-icons";
import _ from 'lodash';
import { useHistory, useLocation, useParams } from "react-router";
import { UserContext } from "./context";
import BetterLeftNav from './BetterLeftNav'
import NavSearchBox from './fragments/NavSearchBox'
import BetterTopNav from './BetterTopNav'
import TrackedDocsSidePanel from './TrackedDocsSidePanel'
import RecentDocsSidePanel from './RecentDocsSidePanel'
import isElectron from 'is-electron'
import YearTypeIndexPanel from './YearTypeIndexSidePanel'
import YearMonIndexPanel from './YearMonIndexSidePanel'
import { SearchContext } from './contexts/SearchContext'
import { modalId as searchHelpModalTargetId } from './fragments/SearchHelpModal'
import './styles/case-viewer.scss'
import { stringDateWithoutTime, stringDateWithTime } from './lib/dateutils'
import { isMobile } from "./customhooks/useMobile";


type CaseParams = {
  docid: string,
  institution: string,
  docSearchText: string,
}

const getHearingPrefix = (key: string) => {
  switch (key) {
    case 'hour':
      return 'Ora de incepere a sedinţei: '
    case 'panelOfJudges':
      return 'Complet de judecata: '
    case 'number':
      return 'Numărul de soluţionare: '
    case 'docDate':
      return 'Data de soluţionare: '
    case 'hearingDocType':
      return 'Tipul de soluţionare: '
    case 'solution':
      return 'Soluţie: '
    case 'solutionSummary':
      return 'Detalii soluţie: '
    default:
      return '';
  }
}

const CaseViewer = () => {

  const { docid, institution, docSearchText } = useParams<CaseParams>();

  const localDocId = useMemo(() => decodeURIComponent(docid), [docid])
  const localInstitution = useMemo(() => decodeURIComponent(institution), [institution])
  const localDocSearchText = useMemo(() => decodeURIComponent(docSearchText), [docSearchText])

  const mobile = isMobile();

  const {
    api,
    refreshTrackedDocsCountAndStatus,
    unseenCount
  } = useContext(UserContext);

  const {
    overrideSearchParams,
  } = useContext(SearchContext)

  const [localDoc, setLocalDoc] = useState<Record<string, any>>({})

  const getDoc = useCallback(async () => {
    document.title = `Lex - Dosar ${localDocId} - ${localInstitution}`
    if (localDocId && localInstitution && api) {
      const res = await (api as any).getCaseData(localDocId, localInstitution);
      setLocalDoc(res);
    }
  }, [api, localDocId, localInstitution])

  const history = useHistory()
  const location = useLocation()

  const sortedParts = useMemo(() => {
    const groupedParts: Record<string, any> = localDoc && localDoc.parts && localDoc.parts.length ? _.groupBy(localDoc.parts, 'type') : []
    const parts: any[] = [];
    Object.keys(groupedParts).sort((a, b) => a > b ? 1 : -1)
      .forEach(key =>
        parts.push(...groupedParts[key].sort((a: any, b: any) => a.name > b.name ? 1 : -1))
      )
    return parts;
  }, [localDoc])

  const onSearch = () => {
    history.push('/search')
  }

  useEffect(() => {
    getDoc();
  }, [getDoc])

  return (
    <div id="view-case-page" style={{ height: '100%', position: 'relative', overflow: 'hidden', backgroundColor: '#f1f4f8' }}>
      <div
        className=""
        style={{
          marginTop: mobile ? 16 : unseenCount ? 30 : 0,
          height: 'calc(100% - 120px)', // so the header and top margin are removed from 100%
          width: '100%',
          overflowY: 'scroll'
        }}
        id="infiniteScroll"
      >
        {
          localDoc
            ? <div className='container case-viewer-wrapper'>
              <div className='case-viewer-panel gray'>
                <div className='case-viewer-panel-title'>
                  Detalii dosar
                </div>
                <div className='case-viewer-panel-content'>
                  <div className='case-viewer-meta-entry'>
                    <strong>Numărul dosarului: </strong>{localDoc.meta?.number || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Numărul vechi al dosarului: </strong>{localDoc.meta?.oldNUmber || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Data dosarului: </strong>{stringDateWithoutTime(localDoc.meta?.date) || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Ultima modificare a dosarului: </strong>{stringDateWithTime(localDoc.meta?.lastUpdate) || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Materia juridică din care face parte obiectul dosarului: </strong>{localDoc.meta?.division || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Obiectul dosarului: </strong>{localDoc.meta?.object || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Stadiul procesual: </strong>{localDoc.meta?.status || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Instituţia: </strong>{localDoc.meta?.institution || '-'}
                  </div>
                  <div className='case-viewer-meta-entry'>
                    <strong>Părţile din dosar:</strong>
                    {
                      localDoc.parts && localDoc.parts.length
                        ? <ul>
                          {
                            sortedParts.map((part: any, idx: number) => <li key={idx}>{part.name} {part.type ? `- ${part.type}` : ''}</li>)
                          }
                        </ul>
                        : '-'
                    }

                  </div>
                </div>
              </div>
              <div className='case-viewer-panel'>
                <div className='case-viewer-panel-title'>
                  Sedinte de judecata
                </div>
                <div className='case-viewer-panel-content'>
                  {
                    localDoc.hearings && localDoc.hearings.length
                      ? <>
                        {
                          !mobile
                            ? <div className='case-viewer-table-row header'>
                              <div className='col-2'>
                                Data sedintei
                              </div>
                              <div className='col-5'>
                                Detaliile sedintei
                              </div>
                              <div className='col-5'>
                                Solutia sedintei
                              </div>
                            </div>
                            : null // don't draw table header on mobile since these will be cards
                        }
                        {
                          localDoc.hearings.map((h: any, idx: number) =>
                            <div key={idx} className='case-viewer-table-row'>
                              <div className={`case-viewer-table-subhead ${!mobile ? 'col-2' : ''}`}>
                                {mobile ? <span className='strong'>Data sedintei: </span> : null}{stringDateWithoutTime(h.date)}
                              </div>
                              <div className={`case-viewer-table-item subline d-flex flex-column gap-half ${!mobile ? 'col-5' : ''}`}>
                                <div className='w-100'>
                                  <strong>{getHearingPrefix('hour')}</strong>{h.hour || '-'}
                                </div>
                                <div className='w-100'>
                                  <strong>{getHearingPrefix('panelOfJudges')}</strong>{h.panelOfJudges || '-'}
                                </div>
                                <div className='w-100'>
                                  <strong>{getHearingPrefix('number')}</strong>{h.number || '-'}
                                </div>
                                <div className='w-100'>
                                  <strong>{getHearingPrefix('docDate')}</strong>{stringDateWithoutTime(h.docDate) || '-'}
                                </div>
                                <div className='w-100'>
                                  <strong> {getHearingPrefix('hearingDocType')}</strong>{h.hearingDocType || '-'}
                                </div>
                              </div>
                              <div className={`case-viewer-table-item d-flex flex-column gap-half ${!mobile ? 'col-5' : ''}`}>
                                <div className='w-100'>
                                  <strong>{getHearingPrefix('solution')}</strong>{h.solution || '-'}
                                </div>
                                <div className='w-100'>
                                  <strong>{getHearingPrefix('solutionSummary')}</strong><br />{h.solutionSummary || '-'}
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </>
                      : '-'
                  }
                </div>
              </div>
              <div className='case-viewer-panel'>
                <div className='case-viewer-panel-title'>
                  Cai de atac
                </div>
                <div className='case-viewer-panel-content'>
                  {
                    localDoc.appeals && localDoc.appeals.length
                      ? <>
                        {
                          !mobile
                            ? <div className='case-viewer-table-row header'>
                              <div className='col-2'>
                                Data declararii
                              </div>
                              <div className='col-5'>
                                Tip
                              </div>
                              <div className='col-5'>
                                Parte declaranta
                              </div>
                            </div>
                            : null
                        }
                        {
                          localDoc.appeals.map((h: any) => <div className='case-viewer-table-row'>
                            <div className={`case-viewer-table-subhead ${!mobile ? 'col-2' : ''}`}>
                            {mobile ? <span className='strong'>Data declararii: </span> : null}{stringDateWithoutTime(h.date)}
                            </div>
                            <div className={`case-viewer-table-item  ${!mobile ? 'col-5' : ''}`}>
                            {mobile ? <span className='strong'>Tip: </span> : null}{h.type}
                            </div>
                            <div className={`case-viewer-table-item  ${!mobile ? 'col-5' : ''}`}>
                            {mobile ? <span className='strong'>Parte declaranta: </span> : null}{h.part}
                            </div>
                          </div>
                          )
                        }
                      </>
                      : '-'
                  }
                </div>
              </div>
            </div>
            : null
        }
      </div>
    </div>
  )
}

export default CaseViewer;
