import React, { useCallback, useEffect, useRef, useState, useContext, createRef } from 'react'
import { TransitionGroup } from 'react-transition-group'
import AdvancedSearchBoxNew from './AdvancedSearchBoxNew'
import { useClikedOutside } from '../customhooks/handleClickOutside'
import { SearchContext, SearchParms } from '../contexts/SearchContext'
import { searchText2searchParamsNew } from '../lib/searchutils'
import { isMobile } from '../customhooks/useMobile'

const NavSearchBox = React.forwardRef(({ onSearch }: { onSearch?: () => void }, ref: any) => {
  const { searchText, overrideSearchParams, setIsCaseSearch, clearResults } = useContext(SearchContext);

  const [localSearchText, setLocalSearchText] = useState(searchText)
  const [showAdvanced, setShowAdvanced] = useState(false)

  const advancedSearchRef = createRef<HTMLDivElement>();

  const clickedOutside = useClikedOutside(advancedSearchRef);
  const mobile = isMobile();

  // const _handleKeydown = useCallback((event: any) => {
  //   if (event.keyCode === 27) setShowAdvanced(false) // hide on escape
  // }, [])

  // useEffect(() => {
  //   document.addEventListener("keydown", _handleKeydown)
  //   return () => document.removeEventListener("keydown", _handleKeydown)
  // }, [_handleKeydown])

  useEffect(() => {
    if (clickedOutside) setShowAdvanced(false);
  }, [clickedOutside])

  useEffect(() => {
    if (searchText !== localSearchText) setLocalSearchText(searchText)
    // don't fix this react-hooks/exhaustive-deps issue - it's intentional
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const toggleAdvanced = useCallback((forcedValue?: boolean) => {
    setShowAdvanced(prev => forcedValue !== undefined ? forcedValue : !prev)
  }, [])


  const performSearch = useCallback(() => {
    sessionStorage.clear();
    toggleAdvanced(false)
    const normalizedSearchText = localSearchText.replace(/ +/g, ' ')

    if (!normalizedSearchText.trim()) return //no point in calling anything if nothing is being searched
    const { isCaseSearch, ...translatedParams } = searchText2searchParamsNew(normalizedSearchText) as unknown as SearchParms;
    console.log(isCaseSearch, translatedParams);
    if (isCaseSearch) {
      setIsCaseSearch(true)
      clearResults()
    }
    overrideSearchParams(translatedParams)
    onSearch?.()

  }, [toggleAdvanced, localSearchText, overrideSearchParams, onSearch, setIsCaseSearch, clearResults])

  const _handleKeyUp = useCallback((event: any) => {
    if (event.keyCode === 13) performSearch() // search on Enter
  }, [performSearch])

  return (
    <div className={`nav-searchbox ${showAdvanced ? 'advanced-visible' : ''}`} style={{ flexGrow: 1, zIndex: 9998, padding: mobile ? '1rem' : undefined }} ref={ref}>
      <div className="d-flex flex-row justify-content-center align-items-center search-controls-wrap">
        <input type="text" id="mainsearchInput" className="form-control" placeholder="Cauta acte. ex: tva, impozit, L1/2016"
          autoFocus
          value={localSearchText}
          onChange={e => setLocalSearchText(e.target.value)}
          onKeyUp={_handleKeyUp}
        />
        <button className="btn btn-metallic btn-search fs-18" type="button" onClick={performSearch}>
          <i className="fa fa-search"></i>
        </button>
        {
          !mobile
            ? <div className="btn btn-metallic-light btn-adv-search btn-lg py-1 d-flex flex-row justify-content-center align-items-center"
              onClick={() => toggleAdvanced()}>
              <i className="fa fa-cogs mr-1"></i>
              <span className="fs-14 d-lg-inline-block d-xl-inline-block d-none">Avansata</span>
            </div>
            : null
        }
      </div>
      {/* <TransitionGroup transitionName="advancedSearch" transitionEnterTimeout={200} transitionLeaveTimeout={200}> */}
      {
        showAdvanced
          ? <div className="adv" id="advancedWrapper" ref={advancedSearchRef}>
            {/* <pre>{JSON.stringify(searchAggs)}</pre> */}
            <AdvancedSearchBoxNew
              onHide={() => toggleAdvanced(false)}
              onSearch={() => { onSearch?.(); toggleAdvanced(false) }}
            // searchAggs={searchAggs}
            // searchParams={searchText2searchParams(searchText)}
            />
          </div>
          : null
      }
      {/* </TransitionGroup> */}
    </div>
  )
})

export default NavSearchBox
