import React from 'react'
import { DocNode, docToDocNodeProps } from './Collapsibles'
import { date2DDMMMMMYYYString, date2ShortDiff } from '../lib/textutils'
import Spinner from './Spinner'


export const RecentDocs = ({ recentDocsSortedByDate, loading }) => (
  <div>
    <Spinner className="small" spin={loading} />
    {recentDocsSortedByDate.map(doc => {
      const docnodeProps = docToDocNodeProps(doc._source, doc._id)
      const { logtime } = doc
      const logtimeDate = new Date(logtime)
      const { dateStr, timeStr } = date2ShortDiff(logtimeDate)
      const logTimeStr = `${dateStr} ${timeStr}`
      const revdetails = docnodeProps.rev_type === "Modificare" ? "Act compus" : docnodeProps.rev_type
      // docnodeProps.status_name = `${docnodeProps.rev_type} din ${date2DDMMMMMYYYString(new Date(doc._source.revdata.rev_date))}`
      docnodeProps.underComponent = <span className="font-italic fs-11">({`${revdetails} din ${date2DDMMMMMYYYString(new Date(doc._source.revdata.rev_date))}`})</span>
      docnodeProps.overComponent = <span className="font-italic text-muted fs-10">{logTimeStr}</span>
      return <DocNode key={`doc-${docnodeProps._id}`} {...docnodeProps} />
    })
    }
  </div>
)

export default class RecentDocsWidget extends React.PureComponent {

  render() {
    const { title, icon, items: recentDocsSortedByDate, loading, onRefresh, mobile = false } = this.props
    return (
      <div className="card home-widget">
        {
          !mobile
            ? <div className="card-header d-flex justify-content-between align-items-center py-2 px-2">
              <div className="title d-flex flex-row align-items-center" style={{ gap: '0.5em' }}>
                {
                  icon
                    ? <i className={`fa fa-${icon}`} />
                    : null
                }
                {title}
              </div>
              <div className="btn btn-light btn-sm" onClick={onRefresh}>
                <i className="fa fa-refresh" />
              </div>
            </div>
            : <div
              onClick={onRefresh}
              style={{
                position: 'absolute',
                top: 0,
                right: '0.2em',
                padding: '0 0.25em',
                zIndex: 1099,
              }}
            >
              <i className="fa fa-refresh" />
            </div>
        }

        <div className="card-body px-1 py-1 doc-index">
          <RecentDocs recentDocsSortedByDate={recentDocsSortedByDate} loading={loading} />
        </div>
      </div>
    )
  }

}
