const {
  REACT_APP_SERVER_URL,
  REACT_APP_ADMIN_URL,
  REACT_APP_VERSION
} = window._env_;

export {
  REACT_APP_SERVER_URL,
  REACT_APP_ADMIN_URL,
  REACT_APP_VERSION
}
